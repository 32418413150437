// app/javascript/packs/flash_messages.js

document.addEventListener('DOMContentLoaded', () => {
    const flashMessages = document.querySelectorAll('.flash-message');
    flashMessages.forEach(message => {
        setTimeout(() => {
            message.style.opacity = '0';
            setTimeout(() => {
                message.style.visibility = 'hidden';
            }, 500);
        }, 5000);  // Messages will disappear after 5 seconds
    });
});